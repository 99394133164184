import React from 'react';
import EmailForm from './EmailForm';

function App() {
  return (
    <div>
      <footer>
        <div id="footer">
          <div class="container">
            <div class="row row-bottom-padded-md">
              <div class="col-md-3">
                <h3 class="section-title">About One Jubilee Cottages</h3>
                <p>An 1800's cottage available for holiday lets in Staffordshre. Rent a cottage for your holiday.</p>
              </div>
              <div class="col-md-3">
                <h3 class="section-title">Contact</h3>
                <p><a href="mailto:stay@onejubileecottages.co.uk">stay@onejubileecottages.co.uk</a></p>
                <p>1 Brewery Lane,<br />
                Great Haywood,<br />
                Staffordshire,<br />
                ST18 0SN.
                </p>
                <p><a href="https://maps.app.goo.gl/go9eTaP9j1kGWht8A" target="_blank" rel="noreferrer">Find us on Google Maps</a></p>
              </div>
              <div class="col-md-3 col-md-push-3">
                <h3 class="section-title">Newsletter</h3>
                <p>Subscribe for our newsletter</p>
                <EmailForm />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p class="fh5co-social-icons">
                  <a href="https://www.instagram.com/one_jubilee_cottages" target="_blank" rel="noreferrer"><i class="icon-instagram"></i></a>
                  <a href="https://maps.app.goo.gl/go9eTaP9j1kGWht8A" target="_blank" rel="noreferrer"><i class="icon-google2"></i></a>
                  <a href="https://maps.app.goo.gl/go9eTaP9j1kGWht8A" target="_blank" rel="noreferrer"><i class="icon-map3"></i></a>
                </p>
                <p>Copyright 2024 One Jubilee Cottages <a href="https://atomwebdesign.co.uk">Web Design and Development</a> by <a href="https://atomwebdesign.co.uk">Atom Web Ltd.</a> All Rights Reserved. Made using <a href="http://freehtml5.co/" target="_blank" rel="noreferrer">freehtml5.co</a></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
